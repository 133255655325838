import React, { useState } from "react";
import client from "../../helpers/Client";
import {
  Button,
  Form,
  Grid,
  Input,
  Checkbox,
  Loader,
  Segment,
} from "semantic-ui-react";
import {
  MyErrorMessage,
  MySuccessMessage,
} from "../../components/AlertMessage";

const emptyFormData = {
  title: "",
  link: "",
  year: "",
  isArchived: false,
};

export const ImportantLinks = () => {
  const [data, setData] = useState(emptyFormData);
  const [result, setResult] = useState({ success: 0, failure: 0 });
  const [loading, setLoading] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    setResult({ success: 0, failure: 0 });

    const dest_url = `/api/importantLinks/add`; 
    client
      .post(dest_url, data)
      .then((res) => {
        console.log(res);
        setResult({ success: 1, failure: 0 });
      })
      .catch((err) => {
        console.log(err);
        setResult({ success: 0, failure: 1 });
      })
      .finally(() => {
        setLoading(false);
        setData(emptyFormData);
      });
  };

  return loading ? (
    <Loader active inline="centered" size="massive" />
  ) : (
    <Segment>
      <Grid columns={1} padded>
        <Grid.Column>
          <h1>Add Important Link</h1>
          <Form onSubmit={submit} loading={loading}>
            <Form.Field
              required
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.value });
              }}
            >
              <label>Title</label>
              <Input
                name="title"
                value={data.title}
                placeholder="Enter the title"
                required
              />
            </Form.Field>
            <Form.Field
              required
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.value });
              }}
            >
              <label>Link</label>
              <Input
                name="link"
                value={data.link}
                placeholder="Enter the link"
                required
              />
            </Form.Field>
            <Form.Field
              required
              onChange={(e) => {
                setData({ ...data, [e.target.name]: e.target.value });
              }}
            >
              <label>Year</label>
              <Input
                name="year"
                value={data.year}
                placeholder="Enter the year (e.g., 2024)"
                required
                type="number"
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label="Is Archived?"
                name="isArchived"
                checked={data.isArchived}
                onChange={(e, { checked }) => {
                  setData({ ...data, isArchived: checked });
                }}
              />
            </Form.Field>
            <Button positive type="submit" fluid>
              <i className="far fa-upload"></i> Add Archived Link
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
      {result.success === 1 && (
        <MySuccessMessage header="Successful" message="Archived Link Added" />
      )}
      {result.failure === 1 && (
        <MyErrorMessage header="Failed" message="Failed to add Archived Link" />
      )}
    </Segment>
  );
};
